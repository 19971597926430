import { useContext } from "react";
import ModalV2 from "../../molecules/modal-v2/modal-v2";
import Modal from "../../molecules/modal/modal";
import { OverlayDispatchContext } from "../../providers/overlay/overlay.provider";
import pop from "../../providers/overlay/pop.overlay.actions";
import retrieve from "../../providers/user/persistence/retrieve";
import nookies from 'nookies';

interface AnnouncementsModal {
  carriageFreeThresholdIncreasePopupMessage?: string;
}

const AnnouncementsModal = ({ carriageFreeThresholdIncreasePopupMessage }) => {
    const overlayDispatch = useContext(OverlayDispatchContext);
const onClose = () => {
    overlayDispatch(pop());
    
    // Check if modal has been shown today
    const modalShownToday = localStorage.getItem('modalShownToday');
    const today = new Date().toISOString().split('T')[0];

    if (modalShownToday === 'true') {
      // Update localStorage only if modal has been shown today
      const lastSessionID = localStorage.getItem('lastSessionID');
      const currentSessionID = retrieve(nookies.get())?.sessionId;
      
      if (lastSessionID === currentSessionID) {
        // Update localStorage only if session ID is the same
        localStorage.setItem('modalShownToday', 'false');
        localStorage.setItem('lastDisplayedDate', today);
      }
    }
  };

  return (
      <ModalV2 alert onClose={() => onClose()}>
        <h5>{carriageFreeThresholdIncreasePopupMessage}</h5>
      </ModalV2>
  )
}

export default AnnouncementsModal;