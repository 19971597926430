import CultureCodeEnum from '../../../../models/culture-code-enum';
import client from '../../client-unauthenticated';

const searchDatasheet = async (
  query: string
): Promise<
  {
    datasheetLink: string;
    datasheetName: string;
  }[]
> => {
  const { data } = await client.get<{
    results: {
      datasheetLink: string;
      datasheetName: string;
    }[];
  }>('/search/datasheet', {
    params: {
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      cultureCode: CultureCodeEnum.EnGb,
      query,
    },
  });

  return data.results;
};

export default searchDatasheet;
