import { GetServerSideProps, NextPage } from 'next';
import Error from 'next/error';
import React, { useContext, useEffect } from 'react';
import AccountDashboardPage from '../components/templates/account-dashboard-page/account-dashboard-page';
import AccountPage from '../components/templates/account-page/account-page';
import BasketPage from '../components/templates/basket-page/basket-page';
import ContentPage from '../components/templates/content-page/content-page';
import getContentPage from '../helpers/get-content-page';
import getErrorResponse from '../helpers/get-error-response';
import useLogoutAfterInactivity from '../hooks/use-logout-after-inactivity/use-logout-after-inactivity';
import ContentPageDocumentTypeEnum from '../models/content-page-document-type-enum';
import { ContentPageType } from '../models/content-page-type';
import HttpException from '../services/exceptions/http-exception';
import { OverlayDispatchContext } from '../components/providers/overlay/overlay.provider';
import { useRouter } from 'next/router';
import AnnouncementsModal from '../components/organisms/announcements-modal/announcements-modal';
import open from '../components/providers/overlay/open.overlay.actions';

interface Props {
  readonly page: ContentPageType | null;
  readonly statusCode: number;
}

const DynamicPage: NextPage<Props> = ({ page, statusCode }: Props) => {
  useLogoutAfterInactivity(page);
  const router = useRouter();

  const overlayDispatch = useContext(OverlayDispatchContext);
  const today = new Date().toISOString().split('T')[0];
    
  useEffect(() => {
    const lastDisplayedDate = localStorage.getItem('lastDisplayedDate');
    const modalShownToday = localStorage.getItem('modalShownToday');


    if (!modalShownToday || lastDisplayedDate !== today) {
      localStorage.setItem('modalShownToday', 'false');

      if ((router.asPath.includes("basket") ||  router.asPath.includes("account"))) {
        openAnnouncementModal();

      }
      // if (page?.properties?.carriageFreeThresholdIncreasePopupEnabled === 'True') {
      // }
    }
  }, [today, router.asPath]);

  const openAnnouncementModal = () => {
    overlayDispatch(open(<AnnouncementsModal carriageFreeThresholdIncreasePopupMessage={page?.properties?.carriageFreeThresholdIncreasePopupMessage} />, false, true, true));
    localStorage.setItem('modalShownToday', 'true');
    localStorage.setItem('lastDisplayedDate', today);
  };

  useEffect(() => {
    const modalShownToday = localStorage.getItem('modalShownToday');
    if (modalShownToday !== 'true' && (router.asPath.includes("basket") ||  router.asPath.includes("account")) && page?.properties?.carriageFreeThresholdIncreasePopupEnabled === 'True') {
      openAnnouncementModal();
    }
  }, [router.asPath]);


  if (statusCode) {
    return <Error statusCode={statusCode} />;
  }

  if (page.documentType === ContentPageDocumentTypeEnum.AccountDashboardPage) {
    return <AccountDashboardPage {...page} />;
  }

  if (page.documentType === ContentPageDocumentTypeEnum.AccountPage) {
    return <AccountPage {...page} />;
  }

  if (page.documentType === ContentPageDocumentTypeEnum.BasketPage) {
    return <BasketPage page={page} />;
  }

  return <ContentPage {...page} />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const page = await getContentPage(
      `/${((context.params?.slug || []) as string[]).join('/')}`,
      Boolean(context.query.preview ?? false),
      context.query.token ?? ''
    );

    if (
      !page?.regions?.main ||
      page.documentType === ContentPageDocumentTypeEnum.ProductDetailPage
    ) {
      throw new HttpException(404);
    }

    // Rendering for SSG, commented out to test performance of SSR
    // const revalidate = parseInt(
    //   process.env.NEXT_PUBLIC_SSG_REVALIDATION_TIMEOUT,
    //   10
    // );

    return {
      props: {
        page,
      },
      // Rendering for SSG, commented out to test performance of SSR
      // revalidate,
    };
  } catch (error) {
    if (error.response?.status === 404 || error.status === 404)
      return { notFound: true };

    return getErrorResponse(error);
  }
};

// Rendering for SSG, commented out to test performance of SSR
// export const getStaticPaths: GetStaticPaths = async () => {
//   const pageList = await pageListFlat('/');

//   const paths = pageList
//     .filter(
//       ({ url }) =>
//         !url.startsWith('/brands') &&
//         !url.startsWith('/category-landing') &&
//         !url.startsWith('/search-results')
//     )
//     .map(({ url }) => ({
//       params: {
//         slug: url === '/' ? [] : url.substring(1, url.length - 1).split('/'),
//       },
//     }));

//   return {
//     paths,
//     fallback: 'blocking',
//   };
// };

export default DynamicPage;
