import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import ButtonPrimary from '../../components/atoms/button-primary/button-primary';
import Modal from '../../components/molecules/modal/modal';
import close from '../../components/providers/overlay/close.overlay.actions';
import open from '../../components/providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../components/providers/overlay/overlay.provider';
import useIsLoggedIn from '../../components/providers/user/hooks/use-is-logged-in';
import useLogout from '../../components/providers/user/hooks/use-logout.hook';
import { UserStateContext } from '../../components/providers/user/user.provider';
import { ContentPageType } from '../../models/content-page-type';
import useIntervalEffect from '../use-interval-effect/use-interval-effect';

const useLogoutAfterInactivity = (page: ContentPageType): void => {
  const router = useRouter();
  const { auth } = useContext(UserStateContext);
  const overlayDispatch = useContext(OverlayDispatchContext);
  const isLoggedIn = useIsLoggedIn();
  const logout = useLogout();

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const showPopupAndLogout = () => {
    setPopupIsOpen(true);

    overlayDispatch(
      open(
        <Modal alert>
          <div className="flex flex-col space-y-4 items-center">
            <p>Your basket has expired and you have been logged out.</p>
            <ButtonPrimary
              name="Ok"
              onClick={() => {
                overlayDispatch(close());
                setPopupIsOpen(false);
              }}
            />
          </div>
        </Modal>
      )
    );

    logout();

    router.push(page.regions.header.accountLoginPageLink.url);
  };

  useIntervalEffect(
    () => {
      if (!auth?.sessionExpiresAt) {
        return;
      }

      const expiryTimePassed = dayjs().diff(dayjs(auth.sessionExpiresAt)) > 0;

      if (isLoggedIn && !popupIsOpen && expiryTimePassed) {
        showPopupAndLogout();
      }
    },
    1000,
    [auth?.sessionExpiresAt, popupIsOpen]
  );
};

export default useLogoutAfterInactivity;
