import React, { createElement, FC, useContext } from 'react';
import { AccountNavigationType } from '../../../models/account-navigation-type';
import { BulkOrderingType } from '../../../models/bulk-ordering-type';
import { ContentPageComponentDataType } from '../../../models/content-page-component-data-type';
import { ContentPageType } from '../../../models/content-page-type';
import { LatestCatalogueType } from '../../../models/latest-catalogue-type';
import { LatestOrdersType } from '../../../models/latest-orders-type';
import { QuickAddToolType } from '../../../models/quick-add-tool';
import { TechnicalDatasheetsBlockType } from '../../../models/technical-datasheets-block-type';
import { UpdateDetailsBlockType } from '../../../models/update-details-block-type';
import AnimationFadeIn from '../../animations/animation-fade-in/animation-fade-in';
import ComponentRenderError from '../../atoms/component-render-error/component-render-error';
import Layout from '../../layout/layout';
import Meta from '../../molecules/meta/meta';
import AccountNavigation from '../../organisms/account-navigation/account-navigation';
import Breadcrumb from '../../organisms/breadcrumb/breadcrumb';
import BulkOrdering from '../../organisms/bulk-ordering/bulk-ordering';
import Components from '../../organisms/components';
import Footer from '../../organisms/footer/footer';
import Header from '../../organisms/header/header';
import QuickAddTool from '../../organisms/quick-add-tool/quick-add-tool';
import TechnicalDatasheetsBlock from '../../organisms/technical-datasheets-block/technical-datasheets-block';
import UpdateDetailsBlock from '../../organisms/update-details-block/update-details-block';
import { OverlayStateContext } from '../../providers/overlay/overlay.provider';
import styles from '../page.module.scss';
import usePermissionRedirect from '../../../hooks/use-permission-redirect/use-permission-redirect';

const AccountDashboardPage: FC<ContentPageType> = (page: ContentPageType) => {
  usePermissionRedirect(page);

  const overlayState = useContext(OverlayStateContext);
  const { regions } = page;

  const latestOrders = 'latestOrdersComponent';
  const latestCatalogue = 'latestCatalogueBlockContentComponent';
  const isFixedLayout = (name: string) =>
    ![latestOrders, latestCatalogue].includes(name);

  const findComponent = (
    componentName: string
  ): { name: string; data: ContentPageComponentDataType } =>
    regions.main.components.find(({ name }) => name === componentName);

  const navComponent = findComponent('accountNavigationComponent');
  const quickAddToolComponent = findComponent('quickAddToolComponent');
  const bulkOrderingComponent = findComponent('bulkOrderingComponent');
  const updateDetailsComponent = findComponent(
    'updateDetailsBlockContentComponent'
  );
  const technicalDatasheetsComponent = findComponent(
    'technicalDatasheetsBlockContentComponent'
  );

  const components = regions.main.components.filter(
    ({ name }) =>
      ![
        navComponent?.name,
        quickAddToolComponent?.name,
        bulkOrderingComponent?.name,
        updateDetailsComponent?.name,
        technicalDatasheetsComponent?.name,
      ].includes(name)
  );

  return (
    <>
      <Meta page={page} />

      <Header
        {...regions.header}
        className={
          overlayState.components.length && !overlayState.keepNav
            ? styles.blur
            : ''
        }
        isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
      />

      <div className={overlayState.components.length ? styles.blur : ''}>
        <main>
          {!page.properties.hideBreadcrumb && (
            <Layout>
              <Breadcrumb links={regions.header.breadcrumb} />
            </Layout>
          )}

          {navComponent && (
            <AccountNavigation
              {...(navComponent.data
                .properties as unknown as AccountNavigationType)}
            />
          )}

          <Layout className="pb-component-spacing-mobile lg:pb-component-spacing">
            {components.map(
              (component: {
                name: string;
                data: ContentPageComponentDataType;
              }) => {
                if (!Components[component.name]) {
                  return (
                    <ComponentRenderError
                      key={component.data.id}
                      name={component.name}
                    />
                  );
                }

                return (
                  <AnimationFadeIn
                    className={
                      component.name === latestCatalogue ? 'relative z-30' : ''
                    }
                    key={component.data.id}
                  >
                    {isFixedLayout(component.name) && (
                      <>
                        {createElement(Components[component.name], {
                          ...component.data.properties,
                        })}
                      </>
                    )}

                    {component.name === latestOrders && (
                      <div className="py-component-spacing-mobile lg:py-component-spacing xl:flex">
                        <div className="xl:w-9/12 xl:mr-4">
                          {createElement(Components[component.name], {
                            ...(component.data
                              .properties as unknown as LatestOrdersType),
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          } as any)}
                        </div>

                        <div className="md:grid md:grid-cols-2 md:gap-4 xl:block xl:w-3/12">
                          {quickAddToolComponent && (
                            <QuickAddTool
                              {...(quickAddToolComponent.data
                                .properties as unknown as QuickAddToolType)}
                              small
                              multiple
                            />
                          )}

                          {bulkOrderingComponent && (
                            <div>
                              <BulkOrdering
                                {...(bulkOrderingComponent.data
                                  .properties as unknown as BulkOrderingType)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {component.name === latestCatalogue && (
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-4">
                        {createElement(Components[component.name], {
                          ...(component.data
                            .properties as unknown as LatestCatalogueType),
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        } as any)}

                        <UpdateDetailsBlock
                          {...(updateDetailsComponent.data
                            .properties as unknown as UpdateDetailsBlockType)}
                        />

                        <TechnicalDatasheetsBlock
                          {...(technicalDatasheetsComponent.data
                            .properties as unknown as TechnicalDatasheetsBlockType)}
                        />
                      </div>
                    )}
                  </AnimationFadeIn>
                );
              }
            )}
          </Layout>
        </main>

        <Footer
          {...regions.footer}
          isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
        />
      </div>
    </>
  );
};

export default AccountDashboardPage;
