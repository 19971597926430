import { Field, Form, Formik, FormikProps } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useRef, useState, useContext } from 'react';
import * as yup from 'yup';
import useOutsideClick from '../../../hooks/use-outside-click/use-outside-click';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { TechnicalDatasheetsBlockType } from '../../../models/technical-datasheets-block-type';
import searchDatasheet from '../../../services/search-api/endpoints/search/datasheet';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';

const TechnicalDatasheetsBlockInner: FC<TechnicalDatasheetsBlockType> = ({
  description,
  searchFieldLabel,
}: TechnicalDatasheetsBlockType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const [debounce, setDebounce] = useState<NodeJS.Timeout>(null);
  const [previousSearchTerm, setPreviousSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<
    { datasheetLink: string; datasheetName: string }[]
  >([]);
  const containerComponent = useRef<HTMLDivElement>();

  useOutsideClick(containerComponent, () => setSearchResults([]));

  const onSubmit = async (searchTerm: string): Promise<void> => {
    if (searchTerm) {
      const response = await searchDatasheet(searchTerm);
      setSearchResults(response);

      pushToDataLayer(GtmEventNameEnum.DashboardTechsheetSearch);

      return;
    }

    setSearchResults([]);
  };

  return (
    <div className="flex flex-col">
      <p className="mb-6 text-grey-600 text-sm">{description}</p>

      <Formik
        initialValues={{
          searchTerm: '',
        }}
        onSubmit={(values: { searchTerm: string }) => {
          if (debounce) {
            clearTimeout(debounce);
          }

          if (values.searchTerm === previousSearchTerm || !values.searchTerm) {
            onSubmit(values.searchTerm);

            return;
          }

          setPreviousSearchTerm(values.searchTerm);

          setDebounce(setTimeout(() => onSubmit(values.searchTerm), 1000));
        }}
        validationSchema={yup.object().shape({
          searchTerm: yup.string().trim().required(),
        })}
      >
        {(props: FormikProps<{ searchTerm: string }>) => (
          <Form autoComplete="off">
            <div ref={containerComponent} className="relative">
              <Field
                className={`${
                  searchResults?.length ? '' : 'rounded-b-md'
                } outline-none pl-14 pr-6 py-4 placeholder-grey-600 ring-focus focus:ring-1 rounded-t-md text-sm w-full`}
                name="searchTerm"
                onChange={(event) => {
                  props.handleChange(event);
                  props.submitForm();
                }}
                placeholder={searchFieldLabel}
                type="search"
              />

              <div className="absolute flex items-center h-full left-5 top-0">
                <IcomoonIcon color="french-blue" name="search" size="lg" />
              </div>

              <AnimatePresence>
                {!!searchResults.length && (
                  <motion.div
                    animate={{ opacity: 1, y: 0 }}
                    className="absolute top-full left-0 right-0 bg-white shadow-md p-1 rounded-b-md flex flex-col max-h-64 overflow-y-auto overflow-x-hidden z-30"
                    exit={{ opacity: 0, y: 0 }}
                    initial={{ opacity: 0.4, y: -20 }}
                  >
                    {searchResults.map(
                      (searchResult: {
                        datasheetLink: string;
                        datasheetName: string;
                      }) => (
                        <button
                          key={searchResult.datasheetName}
                          type="button"
                          className="px-6 py-3 rounded-md text-left hover:bg-french-blue hover:text-white"
                          onClick={() => {
                            setSearchResults([]);
                            window.open(searchResult.datasheetLink, '_blank');

                            pushToDataLayer(
                              GtmEventNameEnum.DashboardTechsheetDownload
                            );
                          }}
                        >
                          {searchResult.datasheetName}
                        </button>
                      )
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TechnicalDatasheetsBlockInner;
