import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { AccountNavigationType } from '../../../models/account-navigation-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import Layout from '../../layout/layout';
import useUser from '../../providers/user/hooks/use-user.hook';

const AccountNavigation: FC<AccountNavigationType> = ({
  accountNavigationData,
  helloNameLabel,
}: AccountNavigationType) => {
  const router = useRouter();
  const user = useUser();
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const activeRouteClass = 'font-bold border-b-2 border-french-blue ';
  const getActiveRouteClasses = (
    navItem: ContentPageHeaderNavItemType
  ): string => {
    return (`${router.asPath}/`.split('/').length <= 3 &&
      navItem.link.url.split('/').length <= 3) ||
      (navItem.link.url.split('/').length > 3 &&
        `${router.asPath}/`.startsWith(navItem.link.url))
      ? activeRouteClass
      : '';
  };

  return (
    <>
      <div className="absolute bg-grey-20 inset-x-0 shadow-inner lg:-mt-14 pt-6 md:pt-10 lg:pt-24">
        <Layout>
          <div className="flex flex-col lg:flex-row lg:items-end justify-between">
            <h4 className="page-heading-four mb-4 lg:mb-2">
              {user && helloNameLabel.replace('{0}', user.firstName)}
            </h4>

            <nav className="flex overflow-x-auto -mx-4 px-4">
              <ul className="flex mb-2">
                {accountNavigationData.accountNavigationData.map(
                  (navItem: ContentPageHeaderNavItemType) => (
                    <li key={navItem.link.url}>
                      <Link href={navItem.link.url} passHref>
                        <a
                          className={
                            `${getActiveRouteClasses(navItem)}` +
                            'mr-8 lg:mr-0 lg:ml-8 pb-2 text-sm'
                          }
                          href="replace"
                          onClick={() =>
                            pushToDataLayer(GtmEventNameEnum.AccountNav)
                          }
                          target={navItem.link.target}
                        >
                          {navItem.link.name}
                        </a>
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </nav>
          </div>
        </Layout>
      </div>

      <div className="pb-24 md:pb-28 lg:pb-20" />
    </>
  );
};

export default AccountNavigation;
