import React, { createElement, FC, useContext } from 'react';
import { AccountNavigationType } from '../../../models/account-navigation-type';
import { AccountSubNavigationType } from '../../../models/account-sub-navigation-type';
import { ContentPageComponentDataType } from '../../../models/content-page-component-data-type';
import { ContentPageType } from '../../../models/content-page-type';
import AnimationFadeIn from '../../animations/animation-fade-in/animation-fade-in';
import ComponentRenderError from '../../atoms/component-render-error/component-render-error';
import Layout from '../../layout/layout';
import Meta from '../../molecules/meta/meta';
import AccountNavigation from '../../organisms/account-navigation/account-navigation';
import AccountSubNavigation from '../../organisms/account-sub-navigation/account-sub-navigation';
import Breadcrumb from '../../organisms/breadcrumb/breadcrumb';
import Components from '../../organisms/components';
import Footer from '../../organisms/footer/footer';
import Header from '../../organisms/header/header';
import { OverlayStateContext } from '../../providers/overlay/overlay.provider';
import styles from '../page.module.scss';
import usePermissionRedirect from '../../../hooks/use-permission-redirect/use-permission-redirect';

const AccountPage: FC<ContentPageType> = (page: ContentPageType) => {
  usePermissionRedirect(page);
  const overlayState = useContext(OverlayStateContext);
  const { regions } = page;

  const findComponent = (
    componentName: string
  ): { name: string; data: ContentPageComponentDataType } =>
    regions.main.components.find(({ name }) => name === componentName);

  const navComponent = findComponent('accountNavigationComponent');
  const subNavComponent = findComponent('accountSubNavigationComponent');

  const components = regions.main.components.filter(
    ({ name }) => ![navComponent?.name, subNavComponent?.name].includes(name)
  );

  return (
    <>
      <Meta page={page} />

      <Header
        {...regions.header}
        className={
          overlayState.components.length && !overlayState.keepNav
            ? styles.blur
            : ''
        }
        isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
      />

      <div className={overlayState.components.length ? styles.blur : ''}>
        <main>
          {!page.properties.hideBreadcrumb && (
            <Layout>
              <Breadcrumb links={regions.header.breadcrumb} />
            </Layout>
          )}

          {navComponent && (
            <AccountNavigation
              {...(navComponent.data
                .properties as unknown as AccountNavigationType)}
            />
          )}

          <Layout className="pb-component-spacing-mobile lg:pb-component-spacing">
            <div className={subNavComponent ? 'lg:flex' : ''}>
              <div className={subNavComponent ? 'w-full lg:w-3/12' : 'hidden'}>
                {subNavComponent && (
                  <AnimationFadeIn>
                    <AccountSubNavigation
                      {...(subNavComponent.data
                        .properties as unknown as AccountSubNavigationType)}
                    />
                  </AnimationFadeIn>
                )}
              </div>

              <div className={`${subNavComponent ? 'lg:w-9/12' : ''} w-full`}>
                {components.map(
                  (component: {
                    name: string;
                    data: ContentPageComponentDataType;
                  }) => {
                    if (!Components[component.name]) {
                      return (
                        <ComponentRenderError
                          key={component.data.id}
                          name={component.name}
                        />
                      );
                    }

                    return (
                      <AnimationFadeIn key={component.data.id}>
                        {createElement(Components[component.name], {
                          ...component.data.properties,
                        })}
                      </AnimationFadeIn>
                    );
                  }
                )}
              </div>
            </div>
          </Layout>
        </main>

        <Footer
          {...regions.footer}
          isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
        />
      </div>
    </>
  );
};

export default AccountPage;
