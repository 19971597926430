import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import { AccountSubNavigationType } from '../../../models/account-sub-navigation-type';
import { ContentPageHeaderNavItemType } from '../../../models/content-page-header-nav-item-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import IcomoonIcon from '../../atoms/icomoon-icon/icomoon-icon';

const AccountSubNavigation: FC<AccountSubNavigationType> = ({
  accountNavigationData,
  navigationTitle,
}: AccountSubNavigationType) => {
  const router = useRouter();
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  const isRouteActive = (navItem: ContentPageHeaderNavItemType): boolean =>
    navItem.link.url === `/${(router.query.slug as string[]).join('/')}/`;
  const activeRouteClass = (navItem: ContentPageHeaderNavItemType): string =>
    isRouteActive(navItem)
      ? 'border-french-blue-light text-french-blue-light lg:bg-french-blue lg:text-white'
      : 'border-grey-100 text-grey-600';

  return (
    <nav className="py-component-spacing-mobile lg:py-component-spacing lg:mr-4">
      <h5 className="mb-4 page-heading-five text-center lg:text-left">
        {navigationTitle}
      </h5>

      <ul className="flex lg:block overflow-x-auto">
        {accountNavigationData.accountNavigationData.map(
          (navItem: ContentPageHeaderNavItemType) => (
            <li key={navItem.link.url} className="w-full">
              <Link href={navItem.link.url} passHref>
                <a
                  className={
                    `${activeRouteClass(navItem)} ` +
                    'border-b-2 lg:border-0 flex items-center justify-center lg:justify-between px-4 py-2 lg:rounded-l-lg w-full whitespace-nowrap'
                  }
                  href="replace"
                  onClick={() =>
                    pushToDataLayer(GtmEventNameEnum.AccountSubNav)
                  }
                  target={navItem.link.target}
                >
                  <span
                    className={`${
                      isRouteActive(navItem) ? 'font-bold' : ''
                    } truncate`}
                  >
                    {navItem.link.name}
                  </span>

                  {isRouteActive(navItem) && (
                    <IcomoonIcon
                      color="white"
                      className="hidden lg:block -mb-2px"
                      name="chevron-right"
                      size="sm"
                    />
                  )}
                </a>
              </Link>
            </li>
          )
        )}
      </ul>
    </nav>
  );
};

export default AccountSubNavigation;
