import React, { FC } from 'react';
import { TechnicalDatasheetsBlockType } from '../../../models/technical-datasheets-block-type';
import AccountBlockCard from '../../molecules/account-block-card/account-block-card';
import TechnicalDatasheetsBlockInner from '../../molecules/technical-datasheets-block-inner/technical-datasheets-block-inner';

const TechnicalDatasheetsBlock: FC<TechnicalDatasheetsBlockType> = (
  props: TechnicalDatasheetsBlockType
) => {
  const { heading } = props;

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing-content">
      <AccountBlockCard heading={heading}>
        <TechnicalDatasheetsBlockInner {...props} />
      </AccountBlockCard>
    </div>
  );
};

export default TechnicalDatasheetsBlock;
