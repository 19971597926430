import React, {
  createElement,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import dynamic from 'next/dynamic';
import { BasketDetailsType } from '../../../models/basket-details-type';
import ComponentEnum from '../../../models/component-enum';
import { ContentPageComponentDataType } from '../../../models/content-page-component-data-type';
import { ContentPageType } from '../../../models/content-page-type';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import AnimationFadeIn from '../../animations/animation-fade-in/animation-fade-in';
import ComponentRenderError from '../../atoms/component-render-error/component-render-error';
import LoadingSpinner from '../../atoms/loading-spinner/loading-spinner';
import TextLink from '../../atoms/text-link/text-link';
import Layout from '../../layout/layout';
import Meta from '../../molecules/meta/meta';
import Breadcrumb from '../../organisms/breadcrumb/breadcrumb';
import Components from '../../organisms/components';
import Footer from '../../organisms/footer/footer';
import Header from '../../organisms/header/header';
import QuickAdd from '../../organisms/quick-add/quick-add';
import { QuickAddType } from '../../../models/quick-add';
import invalidateBasket from '../../providers/basket/actions/invalidate-basket';
import {
  BasketDispatchContext,
  BasketStateContext,
} from '../../providers/basket/basket.provider';
import { OverlayDispatchContext, OverlayStateContext } from '../../providers/overlay/overlay.provider';
import useIsLoggedIn from '../../providers/user/hooks/use-is-logged-in';
import { useRouter } from 'next/router'
import styles from '../page.module.scss';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import AnnouncementsModal from '../../organisms/announcements-modal/announcements-modal';
import open from '../../providers/overlay/open.overlay.actions';
import nookies from 'nookies';
import retrieve from '../../providers/user/persistence/retrieve';

const DynamicBasketDownload = dynamic(
  () => import('../../organisms/basket-download/basket-download')
);
const DynamicBasketTop = dynamic(
  () => import('../../organisms/basket-top/basket-top')
);

const BasketPage: FC<{
  page: ContentPageType;
}> = ({ page }) => {
  const basketState = useContext(BasketStateContext);
  const basketDispatch = useContext(BasketDispatchContext);
  const isLoggedIn = useIsLoggedIn();
  const overlayState = useContext(OverlayStateContext);
  const { regions } = page;
  const { components } = regions.main;
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);
  const [sentToDataLayer, setSentToDataLayer] = useState(false);
  const { carriageFreeThresholdIncreasePopupMessage, carriageFreeThresholdIncreasePopupEnabled } = page?.properties;
  const router = useRouter()
  
  if (
    !sentToDataLayer &&
    basketState.basket !== null &&
    !basketState.isLoading
  ) {
    pushToDataLayer(GtmEventNameEnum.BasketView, {
      ecommerce: {
        detail: {
          products: basketState.basket.lineItems?.map((lineItem) => {
            return {
              price: lineItem.buyPriceFormatted,
              productImage: lineItem.product.properties.webImageLarge1425,
              productName: lineItem.product.name,
              productCode: lineItem.product.variantSku,
              masterCode: lineItem.product.sku,
              listPrice: lineItem.listPriceFormatted,
              quantity: lineItem.quantitySatisfied,
            };
          }),
        },
      },
      basketValue: basketState.total,
    });
    setSentToDataLayer(true);
  }

  useEffect(() => {
    basketDispatch(invalidateBasket());
  }, [isLoggedIn]);

  const mainComponents = components.filter(
    (component) =>
      ![
        ComponentEnum.UspListBasket,
        ComponentEnum.SubtotalDeductions,
        ComponentEnum.BulkOrdering,
        ComponentEnum.SaveAsFavouritesBasket,
        ComponentEnum.YouRecentlyViewed,
      ].includes(component.name)
  );

  const sidebarComponents = components.filter((component) =>
    [
      ComponentEnum.UspListBasket,
      ComponentEnum.SubtotalDeductions,
      ComponentEnum.BulkOrdering,
      ComponentEnum.SaveAsFavouritesBasket,
    ].includes(component.name)
  );

  const footerComponents = components.filter(
    (component) => component.name === ComponentEnum.YouRecentlyViewed
  );

  const basketDetailsComponent = components.find(
    (component) => component.name === ComponentEnum.BasketDetails
  );

  const renderComponent = (component: {
    name: ComponentEnum;
    data: ContentPageComponentDataType;
  }) => {
    if (!Components[component.name]) {
      return (
        <ComponentRenderError key={component.data.id} name={component.name} />
      );
    }

    // TODO: remove old quickAddTool from Basket page in CMS
    if (component.name === ComponentEnum.QuickAddTool) {
      return null;
    }

    // TODO: remove BulkOrdering from Basket page in CMS
    if (component.name === ComponentEnum.BulkOrdering) {
      return null;
    }

    if (component.name === ComponentEnum.QuickAddMultiTool) {
      return (
        <AnimationFadeIn key={component.data.id}>
          <QuickAdd
            labels={
              component.data.properties as unknown as QuickAddType['labels']
            }
            customerCode={false}
          />
        </AnimationFadeIn>
      );
    }

    return (
      <AnimationFadeIn key={component.data.id}>
        {createElement(Components[component.name], {
          ...component.data.properties,
          basket: basketState?.basket,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any)}
      </AnimationFadeIn>
    );
  };

  return (
    <>
      <Meta page={page} />

      <Header
        {...regions.header}
        className={
          overlayState.components.length && !overlayState.keepNav
            ? styles.blur
            : ''
        }
        isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
      />

      <div className={overlayState.components.length ? styles.blur : ''}>
        <main>
          {!page.properties.hideBreadcrumb && (
            <div className="bg-grey-20">
              <Layout className="grid gap-8">
                <Breadcrumb
                  links={regions.header.breadcrumb}
                  secondaryComponent={
                    isLoggedIn || !basketDetailsComponent ? null : (
                      <div className="flex items-center space-x-2">
                        <span
                          className="text-grey-600 py-4"
                          dangerouslySetInnerHTML={{
                            __html: (
                              basketDetailsComponent.data
                                .properties as unknown as BasketDetailsType
                            ).areYouAnExistingCustomerLabel,
                          }}
                        />
                        <TextLink
                          {...(
                            basketDetailsComponent.data
                              .properties as unknown as BasketDetailsType
                          ).areYouAnExistingCustomerLink}
                        />
                      </div>
                    )
                  }
                />
                {basketDetailsComponent && (
                  <DynamicBasketTop
                    {...(basketDetailsComponent.data
                      .properties as unknown as BasketDetailsType)}
                    basket={basketState?.basket}
                  />
                )}
              </Layout>
            </div>
          )}

          <Layout className="grid grid-cols-1 xl:grid-cols-4 gap-x-4">
            {!basketState?.basket && basketState.isLoading ? (
              <LoadingSpinner className="h-96 col-span-1 xl:col-span-4" />
            ) : (
              <>
                <div className="col-span-1 xl:col-span-3">
                  {mainComponents.map((component) =>
                    renderComponent(component)
                  )}
                </div>

                <div className="col-span-1 grid xl:block grid-cols-1 md:grid-cols-2 gap-x-4 xl:py-component-spacing">
                  {sidebarComponents.map((component) =>
                    renderComponent(component)
                  )}
                  {basketDetailsComponent && (
                    <DynamicBasketDownload
                      {...(basketDetailsComponent.data
                        .properties as unknown as BasketDetailsType)}
                      basket={basketState?.basket}
                    />
                  )}
                </div>

                <div className="col-span-1 xl:col-span-4">
                  {footerComponents.map((component) =>
                    renderComponent(component)
                  )}
                </div>
              </>
            )}
          </Layout>
        </main>

        <Footer
          {...regions.footer}
          isAuthenticatedPage={page.properties.hideFromUnauthenticatedUsers}
        />
      </div>
    </>
  );
};

export default BasketPage;
