import React, { FC } from 'react';
import { UpdateDetailsBlockType } from '../../../models/update-details-block-type';
import AccountBlockCard from '../../molecules/account-block-card/account-block-card';
import UpdateDetailsBlockInner from '../../molecules/update-details-block-inner/update-details-block-inner';

const UpdateDetailsBlock: FC<UpdateDetailsBlockType> = (
  props: UpdateDetailsBlockType
) => {
  const { heading } = props;

  return (
    <div className="py-component-spacing-mobile lg:py-component-spacing-content">
      <AccountBlockCard heading={heading}>
        <UpdateDetailsBlockInner {...props} />
      </AccountBlockCard>
    </div>
  );
};

export default UpdateDetailsBlock;
