import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly heading: string;
}

const AccountBlockCard: FC<Props> = ({ children, heading }: Props) => (
  <div className="bg-light-blue h-full px-6 py-4 lg:p-8 rounded-lg">
    <h5 className="page-heading-five mb-4">{heading}</h5>

    <div>{children}</div>
  </div>
);

export default AccountBlockCard;
