import React, { FC, useContext } from 'react';
import {
  DataLayerContext,
  DataLayerContextType,
} from '../../providers/data-layer/data-layer.provider';
import GtmEventNameEnum from '../../../models/gtm-event-name-enum';
import { UpdateDetailsBlockType } from '../../../models/update-details-block-type';
import ButtonPrimary from '../../atoms/button-primary/button-primary';

const UpdateDetailsBlockInner: FC<UpdateDetailsBlockType> = ({
  description,
  updateDetailsLink,
}: UpdateDetailsBlockType) => {
  const { pushToDataLayer } =
    useContext<DataLayerContextType>(DataLayerContext);

  return (
    <div className="flex flex-col">
      <p className="mb-6 text-grey-600 text-sm">{description}</p>

      <ButtonPrimary
        {...updateDetailsLink}
        onClick={() => pushToDataLayer(GtmEventNameEnum.DashboardDetailsCard)}
      />
    </div>
  );
};

export default UpdateDetailsBlockInner;
